import Vue from 'vue'
import Vuex from 'vuex'

import { getToken, setToken, removeToken, getUid, setUid, removeUid, getUser, setUser } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        token: getToken(),
        oken: getUid(),
        user: getUser(),
        pageReload: false
    },
    getters:{
        token: state => state.token = getToken(),
        uid: state => state.uid = getUid(),
        user: state => state.user = getUser()
    },
    mutations:{
        SET_TOKEN: (state, token) => {
            state.token = token
            setToken(token)
        },
        SET_UID: (state, uid) => {
            state.uid = uid
            setUid(uid)
        },
        SET_USER: (state, user) => {
            state.user = user
            setUser(user)
        },
        reloadChange: (state)=>{
            state.pageReload = !state.pageReload;
        }
    },
    actions:{
      // user logout
      logout({ commit }) {
          return new Promise((resolve, reject) => {
              commit('SET_TOKEN', '')
              commit('SET_UID', '')
              removeToken()
              removeUid()

              resolve()
          })
      },

      resetToken({ commit }) {
          return new Promise(resolve => {
            commit('SET_TOKEN', '')
          //   commit('SET_ROLES', [])
            commit('SET_UID', '')
            removeToken()
            removeUid()
            resolve()
          })
        },
    }
})
