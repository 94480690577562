<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less">
// 请提示的样式
.van-toast {
  &.van-toast--middle {
    width: 300px;
    height: 300px;
    .van-toast__icon {
      font-size: 100px;
    }
    .van-toast__text {
      font-size: 30px;
    }
  }
}
</style>
