import Vue from "vue";
import 'vant/lib/index.css'
import { Button, Icon, Cell, CellGroup, Collapse, CollapseItem, Tab, Tabs, Tabbar, TabbarItem, Toast, Form, Field, Search, Popup, NavBar, Notify, Picker } from "vant";
Vue.use(Icon)
.use(Button)
    .use(Cell)
    .use(CellGroup)
    .use(Collapse)
    .use(CollapseItem)
    .use(Tab)
    .use(Tabs)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Toast)
    .use(Form)
    .use(Field)
    .use(Search)
    .use(Popup)
    .use(NavBar)
    .use(Notify)
    .use(Picker)
