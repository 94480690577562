import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const uidKey = 'uid'

export function getToken() {
  return Cookies.get(TokenKey)
}
export function getUser() {
  return JSON.parse(Cookies.get('user') || '{}')
}

export function setUser(user) {
  return Cookies.set('user', JSON.stringify(user))
}


export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getUid() {
  return Cookies.get(uidKey)
}

export function setUid(uid) {
  return Cookies.set(uidKey, uid)
}

export function removeUid() {
  return Cookies.remove(uidKey)
}
